<template>
    <div class="layout">
      <header v-if="!hideMenu">
        <header-panel />
      </header>
      <main class="main" id="main" :style="{ minHeight: mainHeight + 'px' }">
        <component
          :is="component"
          :style="{ minHeight: mainHeight + 'px' }"
        ></component>
      </main>
      <footer v-if="!hideMenu">
        <footer-panel></footer-panel>
      </footer>
      <!-- 添加一个backtop -->
      <el-backtop></el-backtop>
    </div>
  </template>
  <script>
  import HeaderPanel from '@/components/header';
  import FooterPanel from '@/components/footer';
  export default {
    data() {
      return {
        hideMenu: false
      };
    },
    components: {
      HeaderPanel,
      FooterPanel
    },
    asyncComputed: {
      component: function() {
        return this.$utils.getComponent(this.$route.path);
      }
    },
    computed: {
      mainHeight() {
        return window.innerHeight - 220;
      }
    },
    mounted() {
      this.hideMenu = this.getQueryVariable('hideMenu');
    },
    methods: {
      getQueryVariable(variable) {
        const query = window.location.search.substring(1);
        const vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
          const pair = vars[i].split('=');
          if (pair[0] === variable) {
            return pair[1];
          }
        }
        return false;
      }
    }
  };
  </script>
  <style lang="scss" scoped>
  .layout {
    position: relative;
    // height: 100vh;
    // display: flex;
    // flex-direction: column;
    // overflow: hidden;
    header {
      z-index: 100;
      position: sticky;
      top: -1px;
      // width: 100%;
      &.horizontal {
        position: relative;
      }
    }
  }
  .main {
    background: #f6f6f6;
  }
  </style>
